// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {O3_2jG3ew: {hover: true}};

const cycleOrder = ["O3_2jG3ew"];

const variantClassNames = {O3_2jG3ew: "framer-v-17c3rud"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "O3_2jG3ew", image: yl33J6vo1 = {src: new URL("assets/HMIYG8eXq1evMxPcTzBSP1yFyc.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/HMIYG8eXq1evMxPcTzBSP1yFyc.jpg", import.meta.url).href} 512w, ${new URL("assets/HMIYG8eXq1evMxPcTzBSP1yFyc.jpg", import.meta.url).href} 1000w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "O3_2jG3ew", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-75u3v", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-17c3rud", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"O3_2jG3ew"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"O3_2jG3ew-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 547, intrinsicWidth: 1000, pixelHeight: 547, pixelWidth: 1000, ...toResponsiveImage_194x2gw(yl33J6vo1)}} className={"framer-s8fbyi"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"smhQzzSRw"} style={{filter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}} transition={transition} variants={{"O3_2jG3ew-hover": {filter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-75u3v [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-75u3v * { box-sizing: border-box; }", ".framer-75u3v .framer-1sme1vs { display: block; }", ".framer-75u3v .framer-17c3rud { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 1080px; }", ".framer-75u3v .framer-s8fbyi { aspect-ratio: 1.8214285714285714 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 560px); overflow: hidden; position: relative; width: 1px; }", ".framer-75u3v .framer-v-17c3rud .framer-17c3rud { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-75u3v .framer-17c3rud { gap: 0px; } .framer-75u3v .framer-17c3rud > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-75u3v .framer-17c3rud > :first-child { margin-left: 0px; } .framer-75u3v .framer-17c3rud > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 620
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"nGadhdM4b":{"layout":["fixed","auto"]}}}
 * @framerVariables {"yl33J6vo1":"image"}
 */
const FrameruV2GsUTze: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameruV2GsUTze;

FrameruV2GsUTze.displayName = "Mine what image 5";

FrameruV2GsUTze.defaultProps = {height: 620, width: 1080};

addPropertyControls(FrameruV2GsUTze, {yl33J6vo1: {__defaultAssetReference: "data:framer/asset-reference,HMIYG8eXq1evMxPcTzBSP1yFyc.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameruV2GsUTze, [])